<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Tambah Proses KPR" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Item Proses KPR *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Item KPR" :value="data.nama_item_kpr" @click="isModalItemProsesKprActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalItemProsesKprActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Urut</label>
            <vs-input class="w-full" v-model="data.no_urut" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Estimasi Waktu (Hari)</label>
            <vs-input class="w-full" v-model="data.waktu" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Status</label>
            <v-select :options="statuses" label="status" :reduce="item => item.status" :clearable="false" v-model="data.status"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Tgl. Mulai</label>
            <flat-pickr class="w-full" v-model="data.tgl_mulai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Tgl. Selesai</label>
            <flat-pickr class="w-full" v-model="data.tgl_selesai"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-8/12 w-full">
            <label class="ml-1 text-xs">File</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="data.fileTemp" @change="data.file = $event.target.files[0]"/>
          </div>
          <div class="vx-col w-full sm:w-4/12">
            <div class="float-left mt-3">
              <label class="ml-1 text-xs"></label>
              <vs-checkbox v-model="data.is_akad">Sudah Akad Kredit</vs-checkbox>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" />
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal item proses kpr-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Item KPR"
                :active="isModalItemProsesKprActive"
                v-on:update:active="isModalItemProsesKprActive = $event">
        <ItemProsesKpr v-if="isActive" :selectable="true" :externalFilter="filterItemProsesKpr" @selected="onSelectedModalItemProsesKpr"/>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import KontrakProsesKprRepository from '@/repositories/marketing/kontrak/kontrak-proses-kpr-repository'
import ItemProsesKpr from '@/views/pages/master/item-kpr/ItemKpr'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { convertToFormData } from '@/utilities/common/global-methods'
import _ from 'lodash'

export default {
  name: 'ProsesKprAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    ItemProsesKpr,
    'v-select': vSelect
  },
  computed: {
    storeKontrakDetail () {
      return this.$store.state.marketing.kontrakDetail
    },
    filterItemProsesKpr () {
      const idBankKpr = this.storeKontrakDetail.kontrak.id_bank_kpr
      return idBankKpr ? { id_bank_kpr: idBankKpr } : null
    }
  },
  data () {
    return {
      isLoading: false,
      isModalItemProsesKprActive: false,
      errors: null,
      data: {
        is_akad: false
      },
      statuses: [
        { status: 'PENDING' },
        { status: 'DISETUJUI' },
        { status: 'DITOLAK' },
        { status: 'SELESAI' }
      ]
    }
  },
  methods: {
    onSelectedModalItemProsesKpr (item) {
      this.data.id_item_kpr = item.id
      this.data.nama_item_kpr = item.nama
      this.data.no_urut = item.no_urut
      this.data.waktu = item.waktu
      this.isModalItemProsesKprActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({ ...this.data, id_kontrak: this.$route.params.idKontrak })
      const idKontrak = this.$route.params.idKontrak

      KontrakProsesKprRepository.create(idKontrak, params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks', 'statuses'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
